import { ReactNode } from 'react';
import { matchRoutes } from 'react-router-dom';
import { message } from 'antd';
import { AxiosError } from 'axios';

import { moduleName } from './apexModuleHelper';

const isRouteMatch = (route: string, reqUrl: string) => {
  return matchRoutes([{ path: route }], reqUrl);
};

// classify API error to be reported to Sentry
export const classifyApiError = (err: AxiosError<{ errorCode: string; errorMessage: string }>) => {
  const response: any = err.response || {};
  const reqUrl: string = response.config?.url || '';
  const method = (response?.config?.method || '') as 'get' | 'post' | 'put' | 'delete';

  const errorCode = err.response?.data?.errorCode || '';
  const errorMessage = err.response?.data?.errorMessage || '';
  const httpCode = err.response?.status;

  // functions/src/common/db.ts
  const hashedIds = /(creq|ofrprq|page|pcs|kyc)_[a-f0-9]+/g;
  const reqUrlWithoutHashedIds = reqUrl.replace(hashedIds, ':id');
  //issue title in sentry
  let errorTitle = `${method?.toUpperCase()} ${reqUrlWithoutHashedIds} - ${errorCode || httpCode}`;

  // Create a custom fingerprint for better error grouping
  const fingerprint = ['api', errorCode || httpCode, method, reqUrlWithoutHashedIds];

  // Extract useful information from the error
  const errorInfo = {
    url: reqUrl,
    method,
    status: httpCode,
    errorCode,
    statusText: errorMessage,
    headers: response?.config?.headers,
    requestData: response?.config?.data,
    responseData: response?.data,
  };
  const customError = new Error(err?.toString());
  customError.name = errorTitle;

  let captureErrorDetails = {
    priority: 'p2', //default priority
    needToCapture: true,
    errorInfo,
    error: customError,
    fingerprint,
    requestDetails: errorInfo?.requestData,
    responseDetails: errorInfo?.responseData,
  } as {
    priority: 'p0' | 'p1' | 'p2' | 'p3';
    needToCapture: boolean;
    error?: Error;
    fingerprint?: string[];
    requestDetails?: any;
    responseDetails?: any;
    errorInfo?: any;
  };

  //always capture for 5xx error
  if (httpCode?.toString().startsWith('5')) {
    captureErrorDetails.priority = 'p1';
    return captureErrorDetails;
  }

  //check default Axios error
  if (err.code === 'ERR_BAD_REQUEST') {
    captureErrorDetails.priority = 'p2';
    errorTitle = `Bad URL is called - ${reqUrlWithoutHashedIds}`;
    return captureErrorDetails;
  }
  if (err.code === 'ECONNABORTED' || err.code === 'CanceledError') {
    captureErrorDetails.priority = 'p3';
    errorTitle = `Request is cancelled or aborted - ${reqUrlWithoutHashedIds}`;
    return captureErrorDetails;
  }
  if (err.code === 'ERR_NETWORK') {
    captureErrorDetails.priority = 'p3';
    errorTitle = `Lost internet connection - ${reqUrlWithoutHashedIds}`;
    return captureErrorDetails;
  }
  //FIXME: this is forbackward compatibility, need to categorize by common and module name
  switch (reqUrl) {
    case '/accounts':
      if (errorCode === 'ACCOUNT_DOES_NOT_EXISTS') {
        captureErrorDetails.needToCapture = false;
      }
      break;
    case '/merchants':
      if (errorCode === 'MERCHANT_NOT_FOUND') {
        captureErrorDetails.needToCapture = false;
      }
      break;
    case '/authentications/authenticator':
      if (errorCode === 'AUTHENTICATOR_DOES_NOT_EXISTS') {
        captureErrorDetails.needToCapture = false;
      }
      break;
    case '/gasless_send_cryptos/get_transaction':
      if (errorMessage.startsWith('Amount is too small')) {
        captureErrorDetails.needToCapture = false;
      }
      break;
    case '/authentications/login':
      if (
        errorCode === 'VERIFY_CODE_EXPIRED' ||
        errorCode === 'VERIFY_CODE_DOES_NOT_EXISTS' ||
        errorCode === 'ACCOUNT_DOES_NOT_EXISTS'
      ) {
        captureErrorDetails.needToCapture = false;
      }
      break;
    case '/authentications/verify_code/email':
    case '/offramp/loggedinclient/init/validate':
      if (
        errorCode === 'CONNECT_INVALID_EMAIL' ||
        errorCode === 'OFFRAMP_INVALID_EMAIL' ||
        errorCode === 'PAYMENT_PAGE_INVALID_EMAIL'
      ) {
        captureErrorDetails.needToCapture = false;
      }
      break;
    default:
      if (
        errorMessage.includes('must be a valid email') ||
        errorCode === 'PAYMENT_DECLINED_RISK' ||
        errorCode === 'BANK_NOT_SUPPORTED'
      ) {
        captureErrorDetails.needToCapture = false;
      }
      break;
  }

  //payment related API
  if (moduleName === 'pay' || moduleName === 'pay2') {
    //GET payment details
    if (isRouteMatch('/payment_pages/client/:pageId/:clientSecret', reqUrl) && method === 'get') {
      if (['PAYMENT_NOT_FOUND', 'PAYMENT_PAGE_NOT_FOUND'].includes(errorCode)) {
        captureErrorDetails.needToCapture = false;
      }
    }

    //POST confirm payment type
    if (isRouteMatch('/payment_pages/client/:pageId/:clientSecret/confirm/:paymentType', reqUrl) && method === 'post') {
      //TODO: add more whitelist API error here: NOT_SUPPORTED, MISSING_PARAMETER, WRONG_PARAMETER
      if (httpCode?.toString()?.startsWith('4')) {
        captureErrorDetails.needToCapture = true;
      }
    }

    //POST checkout authorization
    if (isRouteMatch('/payment_pages/client/:pageId/:clientSecret/checkout', reqUrl) && method === 'post') {
      //TODO: add more whitelist API error here
      if (httpCode?.toString()?.startsWith('4')) {
        captureErrorDetails.needToCapture = true;
      }
    }
  }

  return captureErrorDetails;
};

const cryptoRejectedMessages = [
  'user rejected',
  // https://breezecash-3d104e39e.sentry.io/issues/5011405409/?project=4505791306727424&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=14
  'user canceled',
  // https://breezecash-3d104e39e.sentry.io/issues/4906445299/?project=4505791306727424&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=2
  'user disapproved',
];
export const SENTRY_IGNORE_ERRORS = [
  /user rejected/,
  /user canceled/,
  /user disapproved/,

  // Network errors that browsers throw
  'Network request failed',
  'NetworkError',
  'Failed to fetch',
  'NetworkError when attempting to fetch resource',
  'The Internet connection appears to be offline',
  'Fetch failed to connect to a network. Check Internet connection',
  'net::ERR_INTERNET_DISCONNECTED',
  'net::ERR_NETWORK_CHANGED',
  'net::ERR_CONNECTION_TIMED_OUT',
  'net::ERR_NAME_NOT_RESOLVED',
  'net::ERR_CONNECTION_RESET',
  'net::ERR_EMPTY_RESPONSE',

  // Chrome extensions or plugins
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  /^chrome-extension:\/\//,
  /^moz-extension:\/\//,
  /^safari-extension:\/\//,
  'Extension context invalidated',
  'ResizeObserver loop',
  'top.GLOBALS',
  "evaluating 'compilation'",
  'webkit-masked-url',
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // https://github.com/orgs/WalletConnect/discussions/3291
  'No matching key.',
  'WebSocket connection failed for host: wss://relay.walletconnect.com',
  // dynamic internal error
  // https://breezecash-3d104e39e.sentry.io/issues/4918362621/?project=4505791306727424&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=13
  'ZodError',

  // Common browser API errors
  'Request aborted',
  'AbortError',
  'VideoError',
  'NotAllowedError',
  'NotSupportedError',
  'SecurityError',
  'The operation was aborted',
  'The request is not allowed',

  // Script loading errors
  'Loading chunk',
  'Loading CSS chunk',
  'Failed to load script',
  'Missing PDF',
  'ChunkLoadError',

  // Common third-party script errors
  'globalThis is not defined',
  "Can't find variable: global",
  'Cannot redefine property: googletag',
  'Object Not Found Matching Id',
  'brightcove',
  'videojs',

  // Common React-specific errors that are typically handled
  'MinimumViableReactDOMServer',
  'Minified React error',
  'Hydration failed',

  // User actions that trigger benign errors
  'cancelled',
  'Canceled',
  'User cancelled',
  'User denied transaction',
  'Permission denied',
  'Permissions check failed',
  'User rejected',
  'User canceled',
  'User disapproved',
  'AppCheck: ReCAPTCHA error',

  // Regular expressions for more complex patterns
  /^Blocked a frame with origin/,
  /^Request failed with status code/,
  /^Invalid API key provided/,
  /^Cross-Origin Request Blocked/,
  /^The operation is insecure/,
  /^Failed to register a ServiceWorker/,
  /^No web-animations-js/,

  //custom error that is expected
  '[Statsig]',
];

export const SENTRY_IGNORE_TRANSACTIONS = [
  // Static assets
  /\.(gif|jpe?g|png|ico|icon|css|js|map)$/i,

  // Third-party services
  '/google-analytics',
  '/hotjar',
  '/facebook-pixel',

  // Development routes
  '/webpack-hmr',
  '/__webpack_hmr',
  '/__vite_hmr',
];

export const SENTRY_DENY_URLS = [
  // hotjar
  /static\.hotjar\.com/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // statsig
  /api\.statsig\.com/,
  /featuregates\.org/,
  /statsigapi\.net/,
  /events\.statsigapi\.net/,
  /api\.statsigcdn\.com/,
  /featureassets\.org/,
  /assetsconfigcdn\.org/,
  /prodregistryv2\.org/,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

export const kycNotSupportedStates = ['LA', 'AK', 'NY'];

export const isUserRejectedError = (err: Error) => {
  if (typeof err?.message !== 'string') return false;
  const lowercaseMessage = err.message.toLowerCase();
  return cryptoRejectedMessages.some((msg) => lowercaseMessage.includes(msg));
};

/**
 * Main function to filter and classify Sentry error
 * @param err - Error object
 * @returns Error priority
 */
// classify default error by priority using error message string
const p0Messages = ['out of memory', 'system crash', 'Too many re-renders'];
const p1Messages = [
  'timeout error',
  'service unavailable',
  'permission denied',
  'cannot read properties',
  'Hooks must be called in the exact same order',
  'Each child in a list should have a unique',
  "Can't perform a React state update on an unmounted component",
];
const p2Messages = ['validation error', 'invalid input', 'resource not found', 'deprecated method'];
export const filterAndClassifySentryError = (err: Error): { priority: string; fingerprint?: string[] } | null => {
  const isErrorIgnored =
    SENTRY_IGNORE_ERRORS.some((pattern) => err?.message?.match(pattern)) || isUserRejectedError(err);
  if (isErrorIgnored) {
    return null;
  } else if (err?.name === 'ChunkLoadError') {
    // refresh to invalidate cache
    window.location.reload();
    return null;
  }

  //classify by priority
  const errorMessage = err?.message?.toLowerCase() || err?.name?.toLowerCase() || '';
  const p0Match = p0Messages.find((msg) => errorMessage.toLowerCase().includes(msg));
  if (p0Match) {
    return {
      priority: 'P0',
      fingerprint: [p0Match],
    };
  }

  const p1Match = p1Messages.find((msg) => errorMessage.toLowerCase().includes(msg));
  if (p1Match) {
    return {
      priority: 'P1',
      fingerprint: [p1Match],
    };
  }
  const p2Match = p2Messages.find((msg) => errorMessage.toLowerCase().includes(msg));
  if (p2Match) {
    return {
      priority: 'P2',
      fingerprint: [p2Match],
    };
  }

  return {
    priority: 'P1',
  };
};

export const getErrorMessage = (error: TAnyType) => {
  const response = error.response;
  return response?.data?.errorMessage || response?.data?.errorCode || error.message || '';
};

export const commonRequestErrorProcess = (error: TAnyType) => {
  message.error(getErrorMessage(error));
};

export enum EBreezeErrorType {
  INSUFFICIENT_BALANCE = 0,
  APPROVAL_FAIL,
  UNKNOWN,
  TIMEOUT,
  DIFFERENT_CHAIN,
  NO_SUPPORTED_TOKEN,
  USER_REJECTED_TRANSACTION,
  ONLY_DESC,
}

export class BreezeError extends Error {
  public type: EBreezeErrorType;
  public extra: any;
  public customMsg?: string | ReactNode;

  constructor({
    type = EBreezeErrorType.ONLY_DESC,
    customMsg,
    extra,
  }: {
    type?: EBreezeErrorType;
    customMsg?: string | ReactNode;
    extra?: any;
  }) {
    super(EBreezeErrorType[type]);
    this.name = 'BreezeError';

    this.type = type;
    this.extra = extra;
    this.customMsg = customMsg;

    Object.setPrototypeOf(this, BreezeError.prototype);
  }
}
