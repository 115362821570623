import { ALLOWED_DOMAINS } from '@/constants/config';
import { EDeployTag } from '@/constants/enum';
import { isDev } from '@/utils';

export const apexModuleNames = [
  'connect',
  'dashboard',
  'offramp',
  'offramp2',
  'wallet',
  'pay',
  'pay2',
  'invoice',
  'link',
] as const;
export type TApexModuleName = (typeof apexModuleNames)[number];

const getDomains = (prefix: TApexModuleName) => ALLOWED_DOMAINS.map((domain) => `${prefix}.${domain}`);
export const payV2Domains = getDomains('pay2');
export const payDomains = getDomains('pay');
const customerDomains = getDomains('wallet');
const connectDomains = getDomains('connect');
const merchantDomains = getDomains('dashboard');
const offrampDomains = getDomains('offramp');
const offrampV2Domains = getDomains('offramp2');
const invoiceDomains = getDomains('invoice');
const linkDomains = getDomains('link');

const hostname = window.location.hostname;
const matchUrl = (url: string) => hostname.endsWith(url);

// for ci preview
// prefix pattern for firebase preview channel url beamo-payments-[qa|devdp]-[connect|dashboard|offramp|wallet]
// e.g. https://beamo-payments-qa-connect--samuel-enable-preview-chann-iqerf6p0.web.app
const getModulePreviewPrefix = (module: TApexModuleName): string => {
  const tag =
    process.env.REACT_APP_DEPLOY_TAG === EDeployTag.DEVELOPMENT_DP ? 'dev-dp' : process.env.REACT_APP_DEPLOY_TAG;
  return `beamo-payments-${tag}-${module}`;
};

const checkModule = (module: TApexModuleName) => {
  return hostname.startsWith(getModulePreviewPrefix(module));
};

export const isCustomerModule = customerDomains.some(matchUrl) || checkModule('wallet');
export const isConnectModule = connectDomains.some(matchUrl) || checkModule('connect');
export const isDashboardModule =
  (isDev && hostname === 'localhost') || merchantDomains.some(matchUrl) || checkModule('dashboard');
export const isOfframpModule = offrampDomains.some(matchUrl) || checkModule('offramp');
export const isOfframpV2Module = offrampV2Domains.some(matchUrl) || checkModule('offramp2');
export const isPayModule = payDomains.some(matchUrl) || checkModule('pay');
export const isPayV2Module = payV2Domains.some(matchUrl) || checkModule('pay2');

export const isInvoiceModule = invoiceDomains.some(matchUrl) || checkModule('invoice');
export const isLinkModule = linkDomains.some(matchUrl) || checkModule('link');

export const isPaymentApp = isPayModule || isInvoiceModule || isLinkModule;

export const moduleName = (() => {
  if (isConnectModule) return 'connect';
  if (isDashboardModule) return 'dashboard';
  if (isOfframpModule) return 'offramp';
  if (isOfframpV2Module) return 'offramp2';
  if (isPayModule) return 'pay';
  if (isPayV2Module) return 'pay2';
  if (isInvoiceModule) return 'invoice';
  if (isLinkModule) return 'link';
  if (isCustomerModule) return 'wallet';
  return 'unknown';
})();
