import { Axios } from 'axios';

import { ECryptoNetworkSymbol } from '@/constants/chains';
import { ECryptoCurrencySymbol } from '@/constants/tokens';
import { retry } from '@/utils';

import { authedInstance } from './api-base';

export type TCoinPrice = Record<string, string>;

export interface ICoinPriceV2 {
  prices: Map<string, Map<string, number>>;
}

export interface ITokenSwapRouteInfo {
  networkSymbol: ECryptoNetworkSymbol;
  sourceToken: ECryptoCurrencySymbol;
  destToken: ECryptoCurrencySymbol;
  sourceAmount: number;
}
export interface ITokenSwapRoute {
  inAmount: string;
  outAmount: string;
  priceImpactPct: number;
  marketInfos: {
    id: string;
    label: string;
    inputMint: string;
    outputMint: string;
    notEnoughLiquidity: boolean;
    inAmount: string;
    outAmount: string;
    priceImpactPct: number;
    lpFee: object;
    platformFee: {
      amount: string;
      mint: string;
      pct: number;
    };
  }[];
  amount: string;
  slippageBps: number;
  otherAmountThreshold: string;
  swapMode: string;
  fees: {
    signatureFee: number;
    openOrdersDeposits: Array<unknown>;
    ataDeposits: Array<unknown>;
    totalFeeAndDeposits: number;
    minimumSOLForTransaction: number;
  };
}

export interface ITokenSwapInfo {
  route: ITokenSwapRoute;
}

export interface ICommonApi {
  getAccountInfo: () => Promise<BeamoNS.IAccountInfo>;
  getMerchantInfo: () => Promise<BeamoNS.IMerchant[]>;
  initMerchants: (params: { name: string }) => Promise<BeamoNS.IMerchant[]>;
  getCoinPrices: () => Promise<{ prices: TCoinPrice }>;
  getAllCoinPrices: () => Promise<ICoinPriceV2>;
  tokenSwap: (params: ITokenSwapInfo) => Promise<{ tokenSwapId: string; transaction: string }>;
  updateTokenSwap: (params: { tokenSwapId: string; signature?: string }) => Promise<{ data: any }>;
  tokenSwapRoute: (params: ITokenSwapRouteInfo) => Promise<ITokenSwapRoute>;
  getNfts: (params: { network: ECryptoNetworkSymbol }) => Promise<BeamoNS.INFT[]>;
  getAuthenticatorInfo: () => Promise<{
    status?: BeamoNS.TAuthenticatorStatus;
    secret?: string;
    user?: string;
    appName?: string;
  }>;

  authenticatorValidate: (authToken: string) => Promise<{ data?: { validated: boolean } }>;

  getRecoveryCodeInfo: () => Promise<{ recoveryCode: string }>;
  getAuthenticatorEnable: () => Promise<{ user: string; appName: string; secret: string }>;
  reportWalletAddress: (params: { wallet: Required<BeamoNS.IWalletAddress> }) => Promise<TAnyType>;
}

const createCommonApi = (instance: Axios): ICommonApi => {
  return {
    // can not put these merchant apis in dashboardApi
    // which requires merchant info to be ready
    getMerchantInfo() {
      return instance.get('/merchants');
    },
    initMerchants(params) {
      return instance.post(`/merchants/init`, params);
    },

    getAccountInfo() {
      return instance.get('/accounts');
    },

    // retrieve coin prices
    getCoinPrices() {
      return instance.get('/token_prices');
    },

    getAllCoinPrices() {
      return instance.get('/token_prices/all');
    },

    tokenSwap(params) {
      return instance.post('/token_swap', params);
    },

    updateTokenSwap(params: { tokenSwapId: string; signature?: string }) {
      return instance.post('/token_swap/update_token_swap', params);
    },

    tokenSwapRoute(params) {
      return instance.get('/token_swap/get_route', { params });
      // return instance.post('/token_swap/get_route', params);
    },

    getNfts(params) {
      return instance.get('/nfts', { params });
    },
    getAuthenticatorEnable() {
      return instance.post('/authentications/authenticator/enable', {});
    },
    getAuthenticatorInfo() {
      return instance.get('/authentications/authenticator');
    },

    authenticatorValidate(authToken) {
      return instance.post('/authentications/authenticator/validate', { token: authToken });
    },
    reportWalletAddress(params) {
      return instance.post('/accounts', params);
    },

    getRecoveryCodeInfo() {
      return instance.post('/authentications/authenticator/recovery_code/enable', {});
    },
  };
};

export const commonApi = createCommonApi(authedInstance);

export const isAccountsReady = async () => {
  return retry(commonApi.getAccountInfo, 30);
};
export const isMerchantReady = async () => {
  return retry(commonApi.getMerchantInfo, 30);
};
